import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// public
const Login = lazy(() => import('../pages/Login'));

const Dashboard = lazy(() => import('../pages/Dashboard.js'));
const User = lazy(() => import('../pages/User.js'));
const Test = lazy(() => import('../pages/Test.js'));

// Student
const ScoreKecermatan = lazy(() =>
  import('../components/kecermatan/ScorePageKecermatan')
);
const ScoreKecerdasan = lazy(() =>
  import('../components/kecerdasan/ScorePageKecerdasan')
);
const ScoreKepribadian = lazy(() =>
  import('../components/kepribadian/ScorePageKepribadian')
);
const ScoreKebangsaan = lazy(() =>
  import('../components/kebangsaan/ScorePageKebangsaan')
);
const ScoreMatematika = lazy(() =>
  import('../components/matematika/ScorePageMatematika')
);
const ScoreBing = lazy(() =>
  import('../components/bahasainggris/scorePageBing')
);
const ScoreUmum = lazy(() =>
  import('../components/pengetahuan-umum/ScorePengetahuanUmum')
);
const ScoreManajerial = lazy(() =>
  import('../components/manajerial/ScoreManajerial')
);

const TestKecermatan = lazy(() => import('../pages/TestKecermatan.js'));
const TestKecerdasan = lazy(() => import('../pages/TestKecerdasan.js'));
const TestKepribadian = lazy(() => import('../pages/TestKepribadian.js'));
const TestKebangsaan = lazy(() => import('../pages/TestKebangsaan.js'));
const TestMatematika = lazy(() => import('../pages/TestMatematika.js'));
const TestBing = lazy(() => import('../pages/TestBing.js'));
const TestUmum = lazy(() => import('../pages/TestPengetahuanUmum.js'));
const TKM = lazy(() => import('../pages/TKM.js'));

const LatihanKecermatan = lazy(() => import('../pages/LatihanKecermatan.js'));

const NilaiSiswaByUsername = lazy(() =>
  import('../pages/NilaiSiswaByUsername')
);
const SoalBelumAktif = lazy(() => import('../pages/SoalNonAktif'));

export default function Routes() {
  return (
    <Router>
      <Suspense fallback={<p>Loading...</p>}>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/test' component={Test} />
          <Route path='/users' component={User} />
          <Route path='/soal-nonaktif/:id' component={SoalBelumAktif} />

          <Route path='/test-kecermatan' component={TestKecermatan} />
          <Route path='/test-kecerdasan' component={TestKecerdasan} />
          <Route path='/test-kepribadian' component={TestKepribadian} />
          <Route path='/test-kebangsaan' component={TestKebangsaan} />
          <Route path='/test-matematika' component={TestMatematika} />
          <Route path='/test-bing' component={TestBing} />
          <Route path='/test-umum' component={TestUmum} />
          <Route path='/test-manajerial' component={TKM} />

          <Route
            path='/latihan-kecermatan-angka'
            component={LatihanKecermatan}
          />
          <Route
            path='/latihan-kecermatan-simbol'
            component={LatihanKecermatan}
          />
          <Route
            path='/latihan-kecermatan-huruf'
            component={LatihanKecermatan}
          />

          <Route path='/score/kecermatan' component={ScoreKecermatan} />
          <Route path='/score/kecerdasan' component={ScoreKecerdasan} />
          <Route path='/score/kepribadian' component={ScoreKepribadian} />
          <Route path='/score/kebangsaan' component={ScoreKebangsaan} />
          <Route path='/score/matematika' component={ScoreMatematika} />
          <Route path='/score/bahasainggris' component={ScoreBing} />
          <Route path='/score/pengetahuan-umum' component={ScoreUmum} />
          <Route path='/score/manajerial' component={ScoreManajerial} />

          <Route path='/myscore' component={NilaiSiswaByUsername} />
          <Route exact path='*'>
            <div className='h-screen bg-white grid place-items-center'>
              404 Not found
            </div>
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}
